import { Outlet, Navigate } from 'react-router-dom'
import TokenService from './token.service'

const PrivateRoutes = () => {
    let user = TokenService.getUser()
    let authorized = false
    if(user == null){authorized = false} else {authorized = true}
    return(
        authorized ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes