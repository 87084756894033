import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoutes from './utilities/privateRoutes.js';
import Login from './login/login'
import SignUp from './signUp/signUp'
import SuccessfulTransaction from './signUp/successfulTransaction/successfulTransaction'
import FailedTransaction from './signUp/failedTransaction/failedTransaction'

import StatementReview from './statementReview/statementReview'
import Policies from './policies/policies.js'
import Support from './support/support.js'
import PasswordReset from './passwordReset/passwordReset'
import MyProfile from './myProfile/myProfile'
import FeedbackSupport from './feedbackSupport/feedbackSupport'
import UserSettings from './userSettings/userSettings.js'


function App() {
  return (
    <div>
      <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<MyProfile />} path="/myProfile" exact/>
              <Route element={<StatementReview />} path="/statementReview" exact/>
              <Route element={<FeedbackSupport />} path="/feedbackSupport" exact/>
              <Route element={<UserSettings />} path="/userSettings" exact/>
            </Route>
            <Route path="*" element={<Navigate to='/'/>}></Route>
            <Route path="/" element={<Login />}></Route> 
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/signup_successful" element={<SuccessfulTransaction />}></Route>
            <Route path="/signup_failed" element={<FailedTransaction />}></Route>
            <Route path="/policies" element={<Policies />}></Route>
            <Route path="/support" element={<Support />}></Route>
            <Route path="/passwordReset" element={<PasswordReset />}></Route>
            
          </Routes>
      </Router>
    </div>
  );
}

export default App;
