import React, {useState,useRef} from 'react'
import TokenService from '../../utilities/token.service'
import API from "../../utilities/api"
import ErrorWindow from '../../errorWindow/errorWindow'
import { useNavigate } from 'react-router-dom';
import '../passwordReset.css'

export default function ResetStatus({progressionState, passwordData}) {
    const navigate = useNavigate()

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    async function logIn(){
        await API.post('officialLogin', {
            phoneNumber: passwordData.phoneNumber,
            password: passwordData.password
        })
        .then(res => {
            console.log(res.data)
            TokenService.setUser(res.data)
            navigate('/statementReview')
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }

    


    return (
    <div style={{display : progressionState == 'resetStatus' ? 'block' : 'none', marginTop: "20px"}}>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <div className='common_note'>You have successfully changed your password! Click the button below to log in and be redirected to Official Responses.</div>
        <div style={{marginLeft: '5px'}} className='common_button common_submit_button' onClick={()=>logIn()}>Okay</div>
    </div>
    )
}
