import React, {useState, useRef, useEffect} from 'react'
import './header.css'
import Navigation from '../navigation/navigation'
import Logo from '../logo/logo'
import newLogo from '../images/SVG_Logo.svg'

export default function Header({hideFunction, openFunction, navDisplay}) {

  const frontCard = useRef(null);


  useEffect(() => {

    if(navDisplay === 'block'){
      frontCard.current.className = 'flip-card-inner front-flip-animation'
    } else {
      frontCard.current.className = 'flip-card-inner back-flip-animation'
    }
  }, [navDisplay]);

  function toggleNavigation(){
      if(navDisplay === 'block'){
      // Hide the navigation menu
      closeNavigation()
    } else {
      // Hide the current page
      openNavigation()
    }
  }


  function openNavigation(){
    hideFunction()   
  }

  function closeNavigation(){
    openFunction()
  }

  return (
    <div>
      <div className='header_section'>
        <div class="flip-card">
          <div ref={frontCard} class="flip-card-inner">
            <div class="flip-card-front login_logo_section" >
              <img className='common_icon_center header_voterstack_logo' src={newLogo}></img>
              <div className="login_voterstack_name_section">
                  <div className="header_voterstack_name">VoterStack</div>
              </div>
            </div>
            <div class="flip-card-back">
              <div>Main Menu</div>
            </div>
          </div>
        </div>

        <div></div>
        <i className="fa fa-bars hamburger_menu_icon center" style={{verticalAlign: 'center'}} onClick={()=>toggleNavigation()}></i>
      </div>

    </div>
  )
}
