import React from 'react'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import './policies.css'

export default function Policies() {

    const navigate = useNavigate()

  /////////////////////////////////////////////////////////////////////
  // ReturnHome
  /////////////////////////////////////////////////////////////////////
  function returnHome(){
    navigate('/')
  }
  return (
    <div className='screen_background policies_screen_background'>
        <div className='policies_screen_foreground'>

  <div>
    <h1 className='policies_main_title'>Terms Of Service</h1>

    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Use of Session Storage Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        At <span className="SpellE">VoterStack</span>, we utilize session
        storage to enhance your browsing experience and provide personalized
        services. By using our website, you agree to the following terms
        regarding the storage of data in your browser sessions:
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. Purpose of Session Storage: Session storage is used to temporarily
        store information specific to your current session on our website. This
        allows us to maintain your preferences, settings, and other relevant
        data while you navigate our site.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        2. Types of Data Stored: The data stored in your browser session may
        include but is not limited to user preferences, login information, and
        other session-specific details to improve your interaction with our
        website.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        3. Security Measures: We take the security and privacy of your data
        seriously. All information stored in your browser session is encrypted
        and protected to prevent unauthorized access or misuse.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        4. Data Retention: Data stored in your browser session is temporary and
        will be automatically deleted once you close your browser or log out of
        your account. We do not retain this data beyond your current session.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        5. User Control: You have the option to clear your browser session data
        at any time by clearing your browser's cache or cookies. This will
        remove any stored information related to your session on our website.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        6. Third-Party Access: We do not share or sell any session storage data
        to third parties. Your information is solely used for improving your
        experience on our website.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        By continuing to use our website, you acknowledge and consent to the use
        of session storage as outlined in this policy. If you have any questions
        or concerns regarding the storage of data in your browser sessions,
        please contact us at{" "}
        <a href="mailto:support@voterstack.com">support@voterstack.com</a>.
      </span>

    </p>



    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Use Of User Created Data Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. Ownership: Any content or data created, submitted, or generated
        through our website, including but not limited to text, images, videos,
        and any other form of media, shall be the exclusive property of
        <span className="SpellE">VoterStack</span> upon submission.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        2. Rights: By submitting content or data to our website, you grant{" "}
        <span className="SpellE">VoterStack</span> a perpetual, irrevocable,
        worldwide, royalty-free, and non-exclusive license to use, reproduce,
        modify, adapt, publish, translate, distribute, and display such content
        in any form or medium.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        3. Data Security: We take all necessary measures to ensure the security
        and integrity of user-created data, including encryption, regular
        backups, and access controls.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        4. Updates: This policy may be updated from time to time to reflect
        changes in our practices or legal requirements. Users will be notified
        of any significant changes to the policy.
      </span>
     
    </p>



    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Data Accuracy Disclosure &amp; Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        At <span className="SpellE">VoterStack</span>, we strive to provide
        accurate and reliable information to our users. However, it is important
        to note that the content created by other users on our platform may not
        always be accurate data. We encourage our users to verify information
        independently before relying on it.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        Accuracy of Coordinates and District Mapping
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        Our coordinates and district mapping services are designed to be as
        accurate as 98%, and we guarantee the reliability of this information.
        We use advanced technology and regularly update our mapping data to
        ensure the highest level of accuracy possible.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">Policy Details</span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        - Users should exercise caution when relying on information provided by
        other users on our platform.
      </span>
      <br />
      <span className="wpaicg-chat-message">
        - We recommend cross-referencing information with reliable sources to
        ensure accuracy.
      </span>
      <br />
      <span className="wpaicg-chat-message">
        - While we strive for 98% accuracy in our coordinates and district
        mapping, there may be occasional discrepancies due to factors beyond our
        control.
      </span>
      <br />
      <span className="wpaicg-chat-message">
        - Users are encouraged to report any inaccuracies they encounter to help
        us improve our services.
      </span>

    </p>






    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Community Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        At <span className="SpellE">VoterStack</span>, we value and encourage
        free speech to its maximum capacity. We believe in fostering a community
        where diverse opinions and ideas can be shared in a respectful manner.
        However, it is important to note that any content created by a user that
        violates the law will not be tolerated.
      </span>
      <br style={{ msoSpecialCharacter: "line-break" }} />
      {/*[if !supportLineBreakNewLine]*/}
      <br style={{ msoSpecialCharacter: "line-break" }} />
      {/*[endif]*/}
      <br />
      <span className="wpaicg-chat-message">Disclosure:</span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. Legal Compliance: Users are expected to abide by all applicable laws
        and regulations when creating content on{" "}
        <span className="SpellE">VoterStack</span>. Any content that violates
        the law will be reported to the necessary authorities.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        2. Reporting Process: If we become aware of any content that breaks the
        law, we will take immediate action to report it to the appropriate
        authorities. This may include providing user information and content
        details to aid in investigations.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        3. User Responsibility: Users are responsible for the content they
        create on <span className="SpellE">VoterStack</span>. It is important to
        ensure that all content is legal and in compliance with our community
        guidelines.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        4. Transparency: We are committed to maintaining transparency in our
        reporting process. Users will be informed if their content is reported
        to authorities and provided with reasons for the action taken.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        5. Enforcement: Violations of the law may result in the removal of
        content, suspension, or termination of user accounts. We reserve the
        right to take appropriate action to uphold legal standards and protect
        the integrity of our community.
      </span>
    </p>






    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Data Protection &amp; Privacy Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        At <span className="SpellE">VoterStack</span>, we take the protection of
        your personal data very seriously. We are committed to ensuring that all
        personal information provided by our users is kept secure and not shared
        with any third parties without explicit consent.
      </span>

      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. Collection of Personal Information: 
      </span>

      <span className="wpaicg-chat-message">
        We may collect personal information such as name, email address, and
        contact details when you register on our website or subscribe to our
        services. This information is used solely for the purpose of providing
        you with the best possible experience on our platform and verifying your
        identity.
      </span>
      <br />
      <br />

      <span className="wpaicg-chat-message">
        2. Use of Personal Information: 
      </span>
      <span className="wpaicg-chat-message">
        Your personal data may be used to personalize your experience on our
        website, improve our services, and communicate with you about updates or
        promotions. We will never sell or share your personal information with
        any unauthorized third parties.
      </span>
      <br />
      <br />

      <span className="wpaicg-chat-message">
        3. Separation of Personal Information: 
      </span>
      <span className="wpaicg-chat-message">
        Your personal information, such as name, email address, and contact
        details, will not be tied to your content or votes on our platform. The
        only exception is the district information you provide, which may be
        used for demographic analysis and to improve user experience.
      </span>
      <br />
      <br />

      <span className="wpaicg-chat-message">
        4. Data Security: 
      </span>
      <span className="wpaicg-chat-message">
        We have implemented strict security measures to protect your personal
        data from unauthorized access, disclosure, alteration, or destruction.
        Your information is stored securely on our servers and is only
        accessible to authorized personnel.
      </span>
      <br />
      <br />

      <span className="wpaicg-chat-message">
        5. Data Deletion: 
      </span>
      <span className="wpaicg-chat-message">
        If you wish to permanently delete your personal information from our
        database, you can do so by contacting our Data Protection Officer at{" "}
        <a href="mailto:support@voterstack.com">support@voterstack.com</a>. Upon
        receiving your request, we will promptly delete all your data from our
        records in compliance with data protection regulations.
      </span>
      <br />
      <br />
    </p>




    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Data Retention Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. Proof of Identity Information:
      </span>
      <br />
      <span className="wpaicg-chat-message">
        - All proof of identity information provided by users for manual
        validation will be promptly deleted upon successful validation by an
        authorized associate. This ensures that sensitive personal data is not
        retained unnecessarily and upholds user privacy and security.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">2. Content Creation:</span>
      <br />
      <span className="wpaicg-chat-message">
        - Content created on the platform will be stored permanently without the
        ability to edit upon submission. This is due to the nature of persistent
        requirements for maintaining the integrity and authenticity of the
        content generated on the platform.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">3. Robust Policy Details:</span>
      <br />
      <span className="wpaicg-chat-message">
        - Our data retention policy is designed to prioritize user privacy and
        security. We adhere to strict guidelines for the deletion of sensitive
        information once validation is complete, ensuring that user data is
        handled responsibly.
      </span>
      <br />
      <span className="wpaicg-chat-message">
        - The permanent storage of content created on the platform serves to
        maintain the accuracy and reliability of the information shared,
        fostering trust among users and promoting transparency in our
        operations.
      </span>
      <br />
      <span className="wpaicg-chat-message">
        - Users are encouraged to review and understand our data retention
        policy to ensure compliance and to address any concerns regarding the
        handling of their personal information and created content.
      </span>
    </p>


    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Communications Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. By providing your contact information and proceeding with our services, you consent to receive text messages and phone calls from us for communication purposes. These messages may include but are not limited to in-app changes, updates, and other relevant information.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        2. Please note that standard message and data rates may apply for text messages. You may opt-out of receiving text messages at any time by replying "STOP" to the message. However, opting out may limit our ability to provide you with certain services or information.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        3. We are committed to protecting your privacy and will not share your contact information with third parties for marketing purposes without your consent. Your information will be used solely for communication related to our services.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        4. If you have any questions or concerns about our text messaging and phone call policy, please contact us at support@voterstack.com.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        5. By proceeding with our services, you acknowledge that you have read and agree to the terms outlined in this policy regarding text messaging and phone calls.
      </span>
    </p>


    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Payment Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. Subscription Requirement: <span className="GramE">In order to</span>{" "}
        access and utilize our services, a monthly subscription fee is required
        to be paid in advance via the designated payment method.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        2. Service Termination: Failure to make the required payment will result
        in the immediate suspension of services until the outstanding payment is
        received.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        3. No Prorating: Please note that we do not offer prorated refunds or
        credits for any unused portion of the subscription period.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        4. Cancellation Policy: If a user decides to cancel their membership
        after a subscription renewal has occurred, no refunds or prorated
        amounts will be provided.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        5. Dispute Resolution: In the event of any payment-related disputes or
        issues, users are required to contact our support team at{" "}
        <a href="mailto:support@voterstack.com">support@voterstack.com</a> for
        assistance and resolution.
      </span>
    </p>






    <p className="MsoNormal policies_section">
      <span className="Heading2Char">
        <span style={{ fontSize: "16.0pt", lineHeight: "107%" }}>
          Consent Policy
        </span>
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        By using our website or reading this document, you agree to the
        following terms:
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        1. Acceptance of Terms: By accessing or using our website, you
        acknowledge that you have read, understood, and agree to be bound by all
        the terms and conditions outlined in this document.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        2. Contact Us: If you have any questions or concerns about our Terms Of
        Service, please contact us at{" "}
        <a href="mailto:support@voterstack.com">support@voterstack.com</a>.
      </span>
      <br />
      <br />
      <span className="wpaicg-chat-message">
        By using our website, you agree to all the terms and conditions outlined
        in this Terms Of Service. If you do not agree with any part of this
        policy, please refrain from using our website.
      </span>
      <br style={{ msoSpecialCharacter: "line-break" }} />
      {/*[if !supportLineBreakNewLine]*/}
      <br style={{ msoSpecialCharacter: "line-break" }} />
      <div className='common_button common_submit_button' onClick={()=>returnHome()}>Return Home</div>
      {/*[endif]*/}
    </p>


  </div>
  </div>
</div>
  )
}
