import axios from "axios";
import TokenService from "./token.service";

let authComm = null

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  authComm = axios.create({
    /*headers: {
      "Content-Type": "application/json"
    }*/
    baseURL: 'https://voterstack.com:5021/',
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": true,
      "Access-Control-Allow-Credentials": true,
  }
  });
} else {
  // production code
  authComm = axios.create({
    /*headers: {
      "Content-Type": "application/json"
    }*/
    baseURL: 'https://voterstack.com:5020/',
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": true,
      "Access-Control-Allow-Credentials": true,
  }
  });
}


authComm.interceptors.request.use(
  (config) => {

    if (config.url === "login") { return config }

    const token = TokenService.getLocalAccessToken();

    

    // JSONify if string
    if (typeof (config.data) === 'string') {
      config.data = JSON.parse(config.data)
      if (token) {
        config.data.accessToken = token;
      }
      config.data = JSON.stringify(config.data)
    } else {
      if (token) {
        config.data.accessToken = token;
      }
    }
    console.log(config)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authComm.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {

    const originalConfig = err.config;

    if (originalConfig.url !== "login" && err.response) {

      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await authComm.post("token", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          TokenService.updateLocalAccessToken(rs.data.accessToken);
          

          return authComm(originalConfig);
        } catch (_error) {
          console.log(" Try did not work")

          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err.response.data);
  }
);

export default authComm;
