import React from 'react'
import './confirmSubmission.css'
import questionMark from '../images/question_ayss.png'

export default function ConfirmSubmission({display, message, close}) {

    function closeHandler(direction){
        close(direction)
    }

    return (
        <div style={{display: display ? 'block' : 'none'}} className='confirm_submission_overlay'>
            <div className='confirm_submission_section'>
                <img className='confirm_submission_icon_center' src={questionMark}></img>
                <div className='confirm_submission_description'>{message}</div>
                <div className='common_button common_ack_button' onClick={()=>closeHandler("advance")}>Submit</div>
                <div style={{marginTop: '1rem'}} className='common_button common_ack_button' onClick={()=>closeHandler("withdraw")}>Cancel</div>
            </div>
        </div>
    )
}
