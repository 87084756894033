import React from 'react'
import './errorWindow.css'
import errorImage from '../images/error.png'

export default function ErrorWindow({display, details, closeError}) {


    function closeErrorHandler(){
        closeError()
    }

  return (
    <div style={{display: display}} className='common_overlay'>
        <div className='error_window_section'>
            <img className='error_window_icon_center' src={errorImage}></img>
            <div className='error_window_title' style={{marginTop: '1rem'}}>{details.title}</div>
            <div className='error_window_description'>{details.description}</div>
            <div className='common_button common_ack_button' onClick={()=>closeErrorHandler()}>Okay</div>
        </div>
    </div>
  )
}
