import React from 'react'
import './navigation.css'
import { useNavigate, useLocation } from 'react-router-dom';
import TokenService from '../utilities/token.service'

export default function Navigation({navigationDisplay}) {
  const navigate = useNavigate()
  const location = useLocation();

 
  function selectNavigation(where){
    if(where === location.pathname){
      window.location.reload()
    }


    switch(where){
      case "/statementReview":
        navigate(where)
      break
      case "/officialResponses":
        navigate(where)
      break
      case "/myProfile":
        navigate(where)
      break
      case "/feedbackSupport":
        navigate(where)
      break
      case "/userSettings":
        navigate(where)
      break
    }

  }

  function signOut(){
    TokenService.removeUser()
    navigate("/")
  }

  return (
    <div style={{display: navigationDisplay}} className="navigation_screen_foreground">
      <div className="navigation_section">
        <div className={location.pathname === '/statementReview' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/statementReview")}>Reports</div>
        <div className={location.pathname === '/myProfile' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/myProfile")}>Profile</div>
        <div className={location.pathname === '/userSettings' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/userSettings")}>Settings</div>
        <div className={location.pathname === '/feedbackSupport' ? "navigation_option navigation_option_highlight" : "navigation_option"} onClick={()=>selectNavigation("/feedbackSupport")}>Feedback & Support</div>
        <div className="navigation_option" style={{color: 'red'}} onClick={()=> signOut()}>Sign Out</div>
      </div>
    </div>
  )
}
