import React, {useEffect, useState, useRef} from 'react'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorWindow from '../../errorWindow/errorWindow'
import addGovernment from '../../images/addGovernment.png'
import '../../common.css'
import ProgressBar from '../../progressBar/progressBar'
import './basicInformation.css'



export default function BasicInformation({display, data, setData, slide}) {
    var baseAddress = null

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    baseAddress = 'https://voterstack.com:5021/'
    } else {
    // production code
    baseAddress = 'https://voterstack.com:5020/'
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    const addressLineOne = useRef()
    const addressLineTwo = useRef()
    const city = useRef()
    const zipCode = useRef()
    const personalPhoneNumber = useRef()
    const personalEmailAddress = useRef()
    const password = useRef()
    const confirmPassword = useRef()
    const [birthDate, setBirthDate] = useState(new Date());


    //////////////////////////////////////////////////////////////
    // State Selection
    //////////////////////////////////////////////////////////////
    const state = [
    { value: 'Minnesota', label: 'Minnesota' }
    ] 

    function handleStateSelect(selectedOption){
        setSelectedState(selectedOption.value)
    }

    const [selectedState, setSelectedState] = useState('');
    const [isStateClearable, setIsStateClearable] = useState(false);
    const [isStateSearchable, setIsStateSearchable] = useState(true);
    const [isStateDisabled, setIsStateDisabled] = useState(false);
    const [isStateLoading, setIsStateLoading] = useState(false);
    const [isStateRtl, setIsStateRtl] = useState(false);

    //////////////////////////////////////////////////////////////
    // Gender Selection
    //////////////////////////////////////////////////////////////
    const [selectedGender, setSelectedGender] = useState('');
    const [isGenderClearable, setIsGenderClearable] = useState(false);
    const [isGenderSearchable, setIsGenderSearchable] = useState(true);
    const [isGenderDisabled, setIsGenderDisabled] = useState(false);
    const [isGenderLoading, setIsGenderLoading] = useState(false);
    const [isGenderRtl, setIsGenderRtl] = useState(false);

    const gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' }
    ] 

    function handleGenderSelect(selectedOption){
        setSelectedGender(selectedOption.value)
    }

    const [selectedAgreement, setSelectedAgreement] = useState(false);

    //////////////////////////////////////////////////////////////
    // Ethnicity Selection
    //////////////////////////////////////////////////////////////
    const [selectedEthnicity, setSelectedEthnicity] = useState('');
    const [isEthnicityClearable, setIsEthnicityClearable] = useState(false);
    const [isEthnicitySearchable, setIsEthnicitySearchable] = useState(true);
    const [isEthnicityDisabled, setIsEthnicityDisabled] = useState(false);
    const [isEthnicityLoading, setIsEthnicityLoading] = useState(false);
    const [isEthnicityRtl, setIsEthnicityRtl] = useState(false);

    const ethnicity = [
        { value: 'asn', label: 'Asian' },
        { value: 'hsl', label: 'Hispanic or Latino' },
        { value: 'aan', label: 'American Indian or Alaska Native' },
        { value: 'aam', label: 'Black or African American' },
        { value: 'wht', label: 'White' },
        { value: 'nhp', label: 'Native Hawaiian or Other Pacific Islander' }
    ] 

    function handleEthnicitySelect(selectedOption){
        setSelectedEthnicity(selectedOption.value)
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('basicInformation', direction)
    }

    //////////////////////////////////////////////////////////////
    // Get Ordinal
    //////////////////////////////////////////////////////////////
    const getOrdinalNum = (number) => {
        let selector;
      
        if (number <= 0) {
          selector = 4;
        } else if ((number > 3 && number < 21) || number % 10 > 3) {
          selector = 0;
        } else {
          selector = number % 10;
        }
      
        return number + ['th', 'st', 'nd', 'rd', ''][selector];
    };

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    //////////////////////////////////////////////////////////////
    // validateUserInformation
    //////////////////////////////////////////////////////////////
    function validateUserInformation(){

        console.log(agreement.current.checked)

        fetch(baseAddress + "validateBasicInformation", {
            method: "POST",
            headers: {
            "content-type" : 'application/json'
            },
            body: JSON.stringify({
                gender: selectedGender,
                ethnicity: selectedEthnicity,
                password: password.current.value,
                confirmPassword: confirmPassword.current.value,
                personalPhoneNumber: personalPhoneNumber.current.value,
                personalEmailAddress: personalEmailAddress.current.value,
                dateOfBirth: birthDate,
                addressLineOne: addressLineOne.current.value,
                addressLineTwo: addressLineTwo.current.value,
                city: city.current.value,
                state: selectedState,
                zipCode: zipCode.current.value,
                agreement: agreement.current.checked
            })
        })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(res => {

            let birthday = birthDate.getDate()
            let birthmonth = birthDate.getMonth()
            let birthyear = birthDate.getFullYear()

            setData({
                password: password.current.value,
                personalPhoneNumber: personalPhoneNumber.current.value,
                personalEmailAddress: personalEmailAddress.current.value,
                dateOfBirth: birthDate,
                gender: selectedGender,
                ethnicity: selectedEthnicity,
                birthday: month[birthmonth] + ' ' + getOrdinalNum(birthday) + ', ' + birthyear,
                addressLineOne: addressLineOne.current.value,
                addressLineTwo: addressLineTwo.current.value,
                city: city.current.value,
                state: selectedState,
                zipCode: zipCode.current.value,
                agreement: agreement.current.checked
            })
            slideHandler(true)
        })
        .catch(error => {
            setErrorDetails(error)
            setErrorDisplay('block')
        })
    }
      

    //////////////////////////////////////////////////////////////
    // handleAgreement
    //////////////////////////////////////////////////////////////
    function handleAgreement(){
        console.log(agreement.current.checked)
        setSelectedAgreement(agreement.current.checked)
    }

    const agreement = useRef()
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// HTML
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div style={{display: display ? 'block' : 'none'}}>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <img className='common_icon_center' src={addGovernment}></img>
        <div className='common_title_subtext'>Great!<br></br> Let's get your account set up!</div>
        <ProgressBar page='basicInformation'></ProgressBar>
        <div className='basic_information_subtitle_subtext' style={{marginTop: '1rem'}}>Please complete and review the form below. Some information has already been prepopulated by an associate from within VoterStack via public office records. Should any information be incorrect, please contact support right away at (333) 333-3333. </div>
        <img src={data.profilePictureURL} alt="Official Photo Missing" className='basic_information_profile_photo'></img>
        
        {/* IDENTITY INFORMATION */}
        <div className='basic_information_subtitle_header'>Identity Information</div>
        <div className='common_input_text'>First Name</div>
        <input disabled value={data.firstName} className='common_input basic_information_prepopulated'></input>
        <div className='common_input_text'>Last Name</div>
        <input disabled value={data.lastName} className='common_input basic_information_prepopulated'></input>
        <div className='common_input_text'>Date Of Birth</div>
        <DatePicker className='common_input' selected={birthDate} onChange={(date) => setBirthDate(date)} />
        <div className='common_input_text'>Gender</div>
        {/* Select gender type */}
        <Select
            onChange={handleGenderSelect}
            className="common_input_text"
            classNamePrefix="select"
            defaultValue={null}
            isDisabled={isGenderDisabled}
            isLoading={isGenderLoading}
            isClearable={isGenderClearable}
            isRtl={isGenderRtl}
            isSearchable={isGenderSearchable}
            name="color"
            options={gender}
            value={gender.filter(function(option) {
            return option.value === selectedGender;
            })}
        />
        <div className='common_input_text'>Ethnicity</div>
        {/* Select ethnicity type */}
        <Select
            onChange={handleEthnicitySelect}
            className="common_input_text"
            classNamePrefix="select"
            defaultValue={null}
            isDisabled={isEthnicityDisabled}
            isLoading={isEthnicityLoading}
            isClearable={isEthnicityClearable}
            isRtl={isEthnicityRtl}
            isSearchable={isEthnicitySearchable}
            name="color"
            options={ethnicity}
            value={ethnicity.filter(function(option) {
            return option.value === selectedEthnicity;
            })}
        />

        {/* ACCESS INFORMATION */}
        <div className='basic_information_subtitle_header'>Access Information</div>
        <div className='common_input_text'>Personal Phone Number</div>
        <input ref={personalPhoneNumber} className='common_input'></input>
        <div className='common_input_text'>Personal Email Address</div>
        <input ref={personalEmailAddress} className='common_input'></input>
        <div className='common_input_text'>Create Password</div>
        <input ref={password} type='password' className='common_input'></input>
        <div className='common_input_text'>Confirm Password</div>
        <input ref={confirmPassword} type='password' className='common_input'></input>


        {/* RESIDENTIAL INFORMATION */}
        <div className='basic_information_subtitle_header'>Residential Information</div>
        <div className='basic_information_subtitle_subtext'>This information will be validated against your residential proof. VoterStack will review this information and remove all residential location data from our systems post verification.</div>

        <div className='common_input_text'>Address Line 1</div>
        <input ref={addressLineOne} className='common_input'></input>
        <div className='common_input_text'>Address Line 2</div>
        <input ref={addressLineTwo} className='common_input'></input>
        <div className='common_input_text'>City</div>
        <input ref={city} className='common_input'></input>
        <div className='common_input_text' style={{marginBottom: '-.5rem'}}>State</div>
        {/* Select state */}
        <Select
            onChange={handleStateSelect}
            className="common_input_text"
            classNamePrefix="select"
            defaultValue={null}
            isDisabled={isStateDisabled}
            isLoading={isStateLoading}
            isClearable={isStateClearable}
            isRtl={isStateRtl}
            isSearchable={isStateSearchable}
            name="color"
            options={state}
            value={state.filter(function(option) {
            return option.value === selectedState;
            })}
        />
        <div className='common_input_text'>Zip Code</div>
        <input ref={zipCode} className='common_input'></input>

        {/* CONTROLLING OFFICE INFORMATION */}
        <div className='basic_information_subtitle_header'>Controlling Office Information</div>
        <div className='common_input_text'>Government Position</div>
        <input disabled value={data.officialTitle} className='common_input'></input>
        <div className='common_input_text'>Government Branch</div>
        <input disabled value={data.branchType} className='common_input'></input>
        <div className='common_input_text'>District Type</div>
        <input disabled value={data.districtType} className='common_input'></input>
        <div className='common_input_text'>District Name</div>
        <input disabled value={data.districtName} className='common_input'></input>
        <div className='common_input_text'>Term Start</div>
        <DatePicker disabled className='common_input' selected={data.termStart}/>
        <div className='common_input_text'>Term End</div>
        <DatePicker disabled className='common_input' selected={data.termEnd}/>
        <div className='common_input_text'>Office Phone Number</div>
        <input disabled value={data.officePhoneNumber} className='common_input'></input>
        <div className='common_input_text'>Office Email Address</div>
        <input disabled value={data.officeEmailAddress} className='common_input'></input>

        <div  style={{marginTop: '40px'}}><input ref={agreement} type='checkbox' onClick={()=>handleAgreement()}></input> By checking this box, you are agreeing to have read and understand our terms of service outlined in our <a href='https://official.voterstack.com/policies' target='_blank' style={{textDecorationLine: 'underline'}}>policies</a> page.</div>
     

        {/* ADVANCE/WITHDRAW */}
        <div className='common_button_combination' style={{marginTop: '3rem'}}>
            <div style={{marginRight: '5px'}} className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
            <div style={{marginLeft: '5px'}} className='common_button common_submit_button' onClick={()=>validateUserInformation()}>Continue</div>
        </div>
    </div>
  )
}
